<template>
    <v-container fluid class="down-top-padding">
        <BaseBreadcrumb :title="trans('router.user.links')" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
        <v-row>
            <v-col cols="12" sm="12">
                <link-table></link-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "index",
        components: {
            BaseBreadcrumb: () => import("@/components/commonComponents/BaseBreadcrumb"),
            LinkTable: () => import("@/components/user/link/LinkTable")
        },
        data(){
            return {
                loading: false,
                errors: null,
                breadcrumbs: [
                    {
                        text: this.trans("router.user.dashboard"),
                        disabled: false,
                        to: {name:'user.dashboard'}
                    },
                    {
                        text: this.trans('router.user.links'),
                        disabled: true
                    }
                ]
            }
        },
    }
</script>
